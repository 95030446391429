.deals-home {
  width: 100%;
  max-width: 400px;
  height: 200px;
  background: linear-gradient(rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.3)),
    url("../../../img/home/deals_home.jpg");
  background-size: cover;
}

.deals-home__wrapper {
  padding: 15px 15px 0;
  margin-top: 10px;
}

.deals-home__heading {
  text-align: center;
  color: aliceblue;
  font-size: 24px;
  line-height: 28px;
  overflow: hidden;
}

.deals-home__paragraph {
  margin-top: 15px;
  overflow: hidden;
  color: aliceblue;
}

.deals-home__button {
  cursor: pointer;
  text-align: center;
  margin-top: 20px;
  color: aliceblue;
  max-width: 100%;
  display: inline-block;
  font-size: 18px;
  font-weight: 600;
  line-height: 18.5px;
  text-decoration: underline;
  text-underline-offset: 5px;
}

/* .deals-home__button::after {
  font-size: 20px;
  margin-left: 7px;
  display: inline-block;
  content: ">";
} */
