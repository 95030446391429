.loader {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.product-item {
  margin-top: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 162px;
  width: 100%;
  background-color: #fafafa;
  box-shadow: 0 0 12px 2px rgb(0 0 0 / 16%);
}

.product-img {
  width: 100%;
}

.product-img__image {
  max-height: calc(162px / 0.736);
  min-height: 162px;
  width: 100%;
}

.product-info {
  display: flex;
  flex-direction: column;
  align-items: center;
  height: auto;
  padding: 8px 8px 8px;
}

.product-info h3 {
  text-align: center;

  white-space: normal;
  font-weight: 500;
  font-size: small;
}

.product-info a {
  /* text-decoration: none; */
  color: black;
}

.product-info__list {
  width: 100%;
  flex-direction: row;
  /* gap: 5px; */
  justify-content: space-between;
}

.product-info__list h3 {
  text-align: start;
}

.product_none {
  display: none;
}
.price {
  /* font-size: 16px;
  color: #fc5a5a;
  display: block;
  margin-bottom: 12px; */
  font-weight: 600;
  /* margin-top: 10px; */
}
.product-price {
  margin-top: 5px;
  /* display: flex;
  justify-content: center; */
  text-align: center;
}

.price__list {
  font-weight: 400;
}

.product-item__list {
  max-width: 570px;
  flex-direction: row;
  margin-top: 5px;
}

@media (max-width: 770px) {
  .product-img__image {
    max-height: calc(108px / 0.736);
    min-height: 108px;
  }

  .product-item {
    width: 108px;
  }

  .product-item__list {
    width: 100%;
  }

  .product-info h3 {
    font-size: small;
  }
  .price_account {
    /* font-size: x-small; */
    font-size: 60%;
    font-weight: 600;
    line-height: 15px;
  }
  .price {
    font-weight: 500;
    font-size: small;
  }
}
