.loader {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.product-item {
  margin-top: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 162px;
  width: 100%;
  background-color: #fafafa;
  box-shadow: 0 0 12px 2px rgb(0 0 0 / 16%);
}

.product-img {
  width: 100%;
}

.product-img__image {
  max-height: calc(162px / 0.736);
  min-height: 162px;
  width: 100%;
}

.product-info {
  display: flex;
  flex-direction: column;
  align-items: center;
  height: auto;
  padding: 8px 8px 8px;
}

.product-info h3 {
  text-align: center;

  white-space: normal;
  font-weight: 500;
  font-size: small;
}

.product-info a {
  /* text-decoration: none; */
  color: black;
}

.price {
  /* font-size: 16px;
  color: #fc5a5a;
  display: block;
  margin-bottom: 12px; */
  font-weight: 600;
  /* margin-top: 10px; */
}
.product-price {
  margin-top: 5px;
  /* display: flex;
  justify-content: center; */
  text-align: center;
}

@media (max-width: 770px) {
  .product-img__image {
    max-height: calc(108px / 0.736);
    min-height: 108px;
  }

  .product-item {
    width: 108px;
  }

  .product-info h3 {
    font-size: small;
  }

  .price {
    font-weight: 500;
    font-size: small;
  }
}
