.game-info__description {
  display: flex;
  gap: 1rem;
  flex-wrap: wrap;
}

.game-info__img {
  width: 160px;
}

.game-info__img img {
  display: block;
  width: 100%;
  object-fit: cover;
  object-position: 50% 50%;
  margin-top: 10px;
  border-radius: 15px;
  background-color: Snow;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

.game-info__specification {
  max-width: 500px;
  width: auto;
}

.game-info__specification a {
  /* text-decoration: none; */
  color: black;
  font-size: 18px;
}

.game-info__short-description {
  margin-top: 10px;
}

@media (max-width: 710px) {
  .game-info__img {
    width: 160px;
  }
}
