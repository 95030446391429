.wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.search {
  width: 100%;
}
.search__title {
  text-align: center;
  margin-top: 20px;
}

.input-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
}

.search-input__wrapper {
  width: 100%;
}

.search-input {
  display: block;
  width: 70%;
  margin: 10px 0;
  padding: 10px;
  border-radius: 10px;
  border: 1px solid rgb(116, 114, 114);
  transition: 0.3s border-color;
}

.search-input:hover {
  border: 1px solid #aaa;
}

.search-list {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 5px;
  position: absolute;
  top: 90%;
  /* left: 15%; */
  width: 70%;
  background-color: rgba(255, 255, 255, 0.96);
  z-index: 1;
}

.search-list__item {
  display: flex;
  gap: 10px;
  align-items: center;
  cursor: pointer;
}

.jotting {
  margin-top: 10px;
  text-align: center;
  color: rgb(231 34 34);
  font-size: 20px;
}
.language-wrapper {
  margin-top: 5px;
  display: flex;
  gap: 0.5rem;
}
.select-language {
  display: block;
  font-family: sans-serif;
  color: #444;
  padding: 0.2em;
  box-sizing: border-box;
  border: 1px solid #aaa;
  box-shadow: 0 1px 0 1px rgba(0, 0, 0, 0.04);
  border-radius: 0.5em;
  background-color: #fff;
}

.btn__search {
  display: inline-block;
  box-sizing: border-box;
  padding: 0 25px;
  margin: 0 15px 15px 0;
  outline: none;
  border: 1px solid #fff;
  border-radius: 50px;
  height: 46px;
  line-height: 46px;
  font-size: 14px;
  font-weight: 600;
  text-decoration: none;
  color: #444;
  background-color: #fff;
  box-shadow: 0 4px 6px rgb(65 132 144 / 10%), 0 1px 3px rgb(0 0 0 / 8%);
  cursor: pointer;
  user-select: none;
  appearance: none;
  touch-action: manipulation;
  vertical-align: top;
  transition: box-shadow 0.2s;
}

.btn__search:focus-visible {
  border: 1px solid #4c51f9;
  outline: none;
}
.btn__search:hover {
  transition: all 0.2s;
  box-shadow: 0 7px 14px rgb(65 132 144 / 10%), 0 3px 6px rgb(0 0 0 / 8%);
}
.btn__search:active {
  background-color: #808080;
}

@media (max-width: 710px) {
  .game-info__description {
    justify-content: center;
  }
}
