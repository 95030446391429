.donate-item {
  display: flex;
  gap: 1rem;
  align-items: center;
  margin-top: 5px;
  justify-content: space-between;
}

.donate-item__info {
  display: flex;
  align-items: center;
  gap: 1rem;
}

.donate-price {
  width: 60px;
}

.loader {
  width: 100px; /* Ширина слоя в пикселах */
  margin: 0 auto; /* Отступ слева и справа */
}
