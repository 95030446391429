.game-info__price {
  text-align: end;
  margin-top: 15px;
}

.game-info__price-text {
  font-size: 25px;
}

@media (max-width: 710px) {
  .game-info__price {
    text-align: center;
  }
}
