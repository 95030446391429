.donate-list {
  display: flex;
  flex-wrap: wrap;
  gap: 1rem;
}

.acor-container {
  margin: 20px 0;
}
.acor-container .acor-body {
  width: calc(100% - 40px);
  margin: 0 auto;
  height: 0;
  color: rgba(0, 0, 0, 0);
  background-color: #bfe2ff;
  line-height: 18px;
  padding: 0 30px;
  box-sizing: border-box;
  transition: color 0.5s, padding 0.5s;
  overflow: hidden;
  font-family: Verdana, sans-serif;
  font-size: 16px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2), 0 10px 16px rgba(0, 0, 0, 0.2);
}
.acor-container .acor-body p {
  margin: 0 0 10px;
}
.acor-container label {
  cursor: pointer;
  background-color: #337ab7;
  /* background-color: #008746; */
  display: block;
  padding: 15px 20px;
  width: 100%;
  color: #bfe2ff;
  font-weight: 300;
  box-sizing: border-box;
  z-index: 100;
  font-family: Verdana, sans-serif;
  font-size: 18px;
  margin: 0 0 5px;
  transition: color 0.35s;
}
.acor-container label:hover {
  color: #fff;
}
.acor-container input {
  display: none;
}
.acor-container label:before {
  content: "\276F";
  float: right;
}
.acor-container input:checked + label {
  background-color: #285f8f;
  color: #fff;
  box-shadow: 0 8px 26px rgba(0, 0, 0, 0.4), 0 28px 30px rgba(0, 0, 0, 0.3);
}
.acor-container input:checked + label:before {
  transition: transform 0.35s;
  transform: rotate(90deg);
}
.acor-container input:checked + label + .acor-body {
  height: auto;
  margin-top: -5px;
  color: #000;
  padding: 20px 30px 10px;
}
