.game-info__price {
  text-align: end;
  margin-top: 15px;

  display: flex;
  flex-direction: row;
  justify-content: center;
  flex-wrap: wrap;
  gap: 10px;
}

.game-info__price-text {
  font-size: 21px;
}

.game-info__price-text-sub {
  margin-top: 15px;
  font-size: 18px;
}

.gpoup-information {
  margin-top: 15px;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 0.5rem;
  flex-direction: column;
}

.gpoup-information__text {
  margin-top: 10px;
  text-align: center;
  /* color: rgb(231 34 34); */
  font-size: 20px;
}

.gpoup-information__logo {
  margin-top: 15px;
  width: 10%;
}

.gpoup-information__title {
  color: black;
  font-size: 18px;
}

@media (max-width: 710px) {
  .game-info__price {
    text-align: center;
  }
}
