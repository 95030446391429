.donate-home {
  width: 100%;
  max-width: 400px;
  height: 200px;
  background: linear-gradient(rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.3)),
    url("../../../img/home/donate_home.jpg");
  background-size: cover;
}

.donate-home__wrapper {
  padding: 15px 15px 0;
  margin-top: 10px;
}

.donate-home__heading {
  text-align: center;
  color: aliceblue;
  font-size: 32px;
  line-height: 28px;
  overflow: hidden;
}

.donate-home__paragraph {
  margin-top: 10px;
  overflow: hidden;
  color: aliceblue;
}
