.search-home {
  width: 100%;
  max-width: 400px;
  height: 200px;

  background: linear-gradient(rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.3)),
    url("../../../img/home/search_home.jpg");

  background-size: cover;
}

.search-home__wrapper {
  padding: 0 15px;
}

.search-home__heading {
  margin-top: 10px;
  text-align: center;
  color: aliceblue;
  font-size: 24px;
  line-height: 28px;
  overflow: hidden;
}

.dsearch-home__paragraph {
  margin-top: 10px;
  overflow: hidden;
  color: aliceblue;
}
