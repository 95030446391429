.search-master {
  width: 100%;
}
.search-input-master {
  display: block;
  width: 100%;
  margin: 10px 0;
  padding: 10px;
  border-radius: 10px;
  border: 1px solid rgb(116, 114, 114);
  transition: 0.3s border-color;
}

.search-input-master:hover {
  border: 1px solid #aaa;
}

.search-list-master {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  position: absolute;
  width: 100%;
  background-color: white;
}

.search-list__item-master {
  cursor: pointer;
}
