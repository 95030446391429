.subscriptions {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 2%;
  justify-content: center;
}

.subscriptions__item {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 15px;
}

.subscriptions__item-img {
  max-width: 150px;
  width: 100%;
}

.subscriptions__item-price {
  font-weight: 600;
  margin-top: 5px;
}

.subscriptions__item-name {
  text-align: center;
  max-width: 180px;
}
