.faq-title {
  margin-top: 20px;

  font-family: "Lato", sans-serif;
  font-weight: 550;
  font-size: 18px;
}

.faq-title a {
  cursor: pointer;
  text-decoration: underline;
  color: blue;
}
.instruction__img {
  max-width: 250px;
  margin-bottom: 10px;
}

.instruction__img img {
  display: block;
  width: 100%;
  object-fit: cover;
  object-position: 50% 50%;
  margin-top: 10px;
  border-radius: 15px;
  background-color: Snow;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

.instruction__img-wrapper {
  display: flex;
  justify-content: center;

  margin-bottom: 10px;
}
